// here we add all base urls and keys

import {
  appLogoIcon,
  appLogoIcon2,
  danielleLogo,
  hinaKhanLogo,
  danielleSidebarLogo,
} from "src/assets";
import { get_project_info_localStorage } from "src/DAL/localStorage/localStorage";
import { main_color_skin_sister } from "src/utils/constants";
import { update_root_value } from "src/utils/domUtils";

// Base URLS
// export const baseUri = "http://192.168.1.250:4615/";

export const baseUri = process.env.REACT_APP_API_BASE_URL;
export const s3baseUrl = process.env.REACT_APP_PIC_BASE_URL;
export const project_name = process.env.REACT_APP_PROJECT_NAME;
export const websiteBaseUrl = process.env.REACT_APP_WEBSITE_BASE_URL;
export const api_key = process.env.REACT_APP_WEB_API_KEY;

// export const project_name = "HINA_KHAN";
// export const project_name = "DANIELLE";
// export const project_name = "DD";
const get_project_info = get_project_info_localStorage();
export const material_theme_palate = {
  lighter: "#d4bff2",
  light: "#ede7f6",
  main: "#1a93a9",
  dark: "#198BA8",
  darker: "#198BA8",
  contrastText: "#fff",
};

export const OwnerName = () => {
  if (project_name == "DD") {
    return "Kim";
  } else if (project_name == "HINA_KHAN") {
    return "Hina";
  } else if (project_name == "DANIELLE") {
    return "Danielle";
  } else if (project_name == "BASIC_DEV") {
    return "BASIC DEV";
  } else if (project_name == "PGI_DEV") {
    return "PGI DEV";
  }
};

export const ownerName = OwnerName() + "'s";
export const ownerCompleteName = OwnerName();

export const get_app_logo = () => {
  if (get_project_info && get_project_info.brand_logo) {
    return s3baseUrl + get_project_info.brand_logo;
  } else {
    if (project_name == "DD") {
      return appLogoIcon;
    } else if (project_name == "HINA_KHAN") {
      return appLogoIcon2;
    } else if (project_name == "DANIELLE") {
      return danielleSidebarLogo;
    } else if (project_name == "BASIC_DEV") {
      return appLogoIcon;
    } else if (project_name == "PGI_DEV") {
      return appLogoIcon;
    }
  }
};

export const get_app_headers = () => {
  if (get_project_info && get_project_info.meta_title) {
    return {
      title: get_project_info.meta_title,
      description: get_project_info.meta_description,
      fav_icon: s3baseUrl + get_project_info.brand_favicon,
    };
  } else {
    if (project_name == "DD") {
      return {
        title: "Dynamite Life Style | Client",
        description: "Dynamite Life Style | Client",
        fav_icon: appLogoIcon,
      };
    } else if (project_name == "HINA_KHAN") {
      return {
        title: "Hina Khan | Client",
        description: "Hina Khan | Client",
        fav_icon: hinaKhanLogo,
      };
    } else if (project_name == "DANIELLE") {
      return {
        title: "DANIELLE | Client",
        description: "DANIELLE | Client",
        fav_icon: danielleLogo,
      };
    } else if (project_name == "BASIC_DEV") {
      return {
        title: "BASIC DEV | Client",
        description: "BASIC DEV | Client",
        fav_icon: appLogoIcon,
      };
    } else if (project_name == "PGI_DEV") {
      return {
        title: "PGI DEV | Client",
        description: "PGI DEV | Client",
        fav_icon: appLogoIcon,
      };
    }
  }
};

// if (project_name == "DD") {
//   material_theme_palate.main = "#f6bd4b";
// } else {
//   material_theme_palate.main = "#1a93a9";
// }

if (project_name == "DD") {
  material_theme_palate.main = "#f6bd4b";
  /* Main theme color */
  update_root_value("--portal-theme-primary", "#f6bd4b");
  update_root_value(
    "--portal-theme-primary-filter-color",
    "invert(74%) sepia(96%) saturate(396%) hue-rotate(332deg) brightness(101%) contrast(93%)"
  );

  update_root_value("--portal-theme-secondary", "rgb(255 218 0 / 8%)");
  update_root_value("--text-primary-color", "white");
  update_root_value("--text-secondary-color", "rgb(150, 150, 150)");
  /* Main theme color end */

  /* Main background color*/
  update_root_value("--background-primary-color", "black");
  update_root_value("--background-secondary-color", "#1d1c1d");

  /* Main background color end*/

  /* Sidebar Properties */
  update_root_value("--sidebars-text-color", "white");
  update_root_value("--sidebars-active-color", "#f6bd4b");
  update_root_value("--sidebars-hover-color:", "#f6bd4b");
  update_root_value("--sidebars-background-color", "#1d1c1d");
  /* Sidebar Properties */

  /* Popup / Popover properties  */
  update_root_value("--popup-background-color", "#1d1c1d");
  update_root_value("--popup-text-color", "white");
  /* Popup / Popover properties end  */

  /* Element properties */
  update_root_value("--input-text-color", "#fff");
  update_root_value("--input-placeholder-color", "#fff");
  update_root_value("--input-background-primary", "#1d1c1d");
  update_root_value("--input-background-secondary", "#1d1c1d");
  update_root_value(" --icons-color-allover", "#f6bd4b");
  /* Element properties ends*/

  /* Button Properties */

  update_root_value("--button-background-color", "#1c1c1c");
  update_root_value("--button-text-color", "#f6bd4b");
  update_root_value("--button-outlined-color", "#f6bd4b");
  update_root_value("--button-hover-color", "#f6bd4b");

  /* Button Properties ends*/

  /* Audio Properties */
  update_root_value("--audio-color", "rgb(56, 54, 54)");
  /* Audio Properties ends*/

  /* calendar Properties */
  update_root_value("--calendar-selected-box", "#8b8b8b");
  update_root_value("--datepicker-selected-color", "#fff");
  /* calendar Properties ends*/
} else if (project_name == "BASIC_DEV") {
  material_theme_palate.main = "#f6bd4b";
  /* Main theme color */
  update_root_value("--portal-theme-primary", "#f6bd4b");
  update_root_value(
    "--portal-theme-primary-filter-color",
    "invert(74%) sepia(96%) saturate(396%) hue-rotate(332deg) brightness(101%) contrast(93%)"
  );
  update_root_value("--portal-theme-secondary", "rgb(255 218 0 / 8%)");
  update_root_value("--text-primary-color", "white");
  update_root_value("--text-secondary-color", "rgb(150, 150, 150)");
  /* Main theme color end */

  /* Main background color*/
  update_root_value("--background-primary-color", "black");
  update_root_value("--background-secondary-color", "#1d1c1d");

  /* Main background color end*/

  /* Sidebar Properties */
  update_root_value("--sidebars-text-color", "white");
  update_root_value("--sidebars-active-color", "#f6bd4b");
  update_root_value("--sidebars-hover-color:", "#f6bd4b");
  update_root_value("--sidebars-background-color", "#1d1c1d");
  /* Sidebar Properties */

  /* Popup / Popover properties  */
  update_root_value("--popup-background-color", "#1d1c1d");
  update_root_value("--popup-text-color", "white");
  /* Popup / Popover properties end  */

  /* Element properties */
  update_root_value("--input-text-color", "#fff");
  update_root_value("--input-placeholder-color", "#fff");
  update_root_value("--input-background-primary", "#1d1c1d");
  update_root_value("--input-background-secondary", "#1d1c1d");
  update_root_value(" --icons-color-allover", "#f6bd4b");
  /* Element properties ends*/

  /* Button Properties */

  update_root_value("--button-background-color", "#1c1c1c");
  update_root_value("--button-text-color", "#f6bd4b");
  update_root_value("--button-outlined-color", "#f6bd4b");
  update_root_value("--button-hover-color", "#f6bd4b");

  /* Button Properties ends*/

  /* Audio Properties */
  update_root_value("--audio-color", "rgb(56, 54, 54)");
  /* Audio Properties ends*/

  /* calendar Properties */
  update_root_value("--calendar-selected-box", "#8b8b8b");
  update_root_value("--datepicker-selected-color", "#fff");
  /* calendar Properties ends*/
} else if (project_name == "DANIELLE") {
  material_theme_palate.main = "#C19D60";
  /* Main theme color */
  update_root_value("--portal-theme-primary", "#C19D60");
  update_root_value(
    "--portal-theme-primary-filter-color",
    "invert(69%) sepia(29%) saturate(570%) hue-rotate(359deg) brightness(88%) contrast(92%)"
  );
  update_root_value("--portal-theme-secondary", "rgb(255 218 0 / 8%)");
  update_root_value("--text-primary-color", "white");
  update_root_value("--text-secondary-color", "rgb(150, 150, 150)");
  /* Main theme color end */

  /* Main background color*/
  update_root_value("--background-primary-color", "black");
  update_root_value("--background-secondary-color", "#1d1c1d");

  /* Main background color end*/

  /* Sidebar Properties */
  update_root_value("--sidebars-text-color", "white");
  update_root_value("--sidebars-active-color", "#C19D60");
  update_root_value("--sidebars-hover-color", "#C19D60");
  update_root_value("--sidebars-background-color", "#1d1c1d");
  /* Sidebar Properties */

  /* Popup / Popover properties  */
  update_root_value("--popup-background-color", "#1d1c1d");
  update_root_value("--popup-text-color", "white");
  /* Popup / Popover properties end  */

  /* Element properties */
  update_root_value("--input-text-color", "#fff");
  update_root_value("--input-placeholder-color", "#fff");
  update_root_value("--input-background-primary", "#1d1c1d");
  update_root_value("--input-background-secondary", "#1d1c1d");
  update_root_value(" --icons-color-allover", "#C19D60");
  /* Element properties ends*/

  /* Button Properties */

  update_root_value("--button-background-color", "#1c1c1c");
  update_root_value("--button-text-color", "#C19D60");
  update_root_value("--button-outlined-color", "#C19D60");
  update_root_value("--button-hover-color", "#C19D60");

  /* Button Properties ends*/

  /* Audio Properties */
  update_root_value("--audio-color", "rgb(56, 54, 54)");
  /* Audio Properties ends*/

  /* calendar Properties */
  update_root_value("--calendar-selected-box", "#8b8b8b");
  update_root_value("--datepicker-selected-color", "#fff");
  /* calendar Properties ends*/
} else if (project_name == "PGI_DEV") {
  material_theme_palate.main = "#f6bd4b";
  /* Main theme color */
  update_root_value("--portal-theme-primary", "#f6bd4b");
  update_root_value(
    "--portal-theme-primary-filter-color",
    "invert(74%) sepia(96%) saturate(396%) hue-rotate(332deg) brightness(101%) contrast(93%)"
  );
  update_root_value("--portal-theme-secondary", "rgb(255 218 0 / 8%)");
  update_root_value("--text-primary-color", "white");
  update_root_value("--text-secondary-color", "rgb(150, 150, 150)");
  /* Main theme color end */

  /* Main background color*/
  update_root_value("--background-primary-color", "black");
  update_root_value("--background-secondary-color", "#1d1c1d");

  /* Main background color end*/

  /* Sidebar Properties */
  update_root_value("--sidebars-text-color", "white");
  update_root_value("--sidebars-active-color", "#f6bd4b");
  update_root_value("--sidebars-hover-color:", "#f6bd4b");
  update_root_value("--sidebars-background-color", "#1d1c1d");
  /* Sidebar Properties */

  /* Popup / Popover properties  */
  update_root_value("--popup-background-color", "#1d1c1d");
  update_root_value("--popup-text-color", "white");
  /* Popup / Popover properties end  */

  /* Element properties */
  update_root_value("--input-text-color", "#fff");
  update_root_value("--input-placeholder-color", "#fff");
  update_root_value("--input-background-primary", "#1d1c1d");
  update_root_value("--input-background-secondary", "#1d1c1d");
  update_root_value(" --icons-color-allover", "#f6bd4b");
  /* Element properties ends*/

  /* Button Properties */

  update_root_value("--button-background-color", "#1c1c1c");
  update_root_value("--button-text-color", "#f6bd4b");
  update_root_value("--button-outlined-color", "#f6bd4b");
  update_root_value("--button-hover-color", "#f6bd4b");

  /* Button Properties ends*/

  /* Audio Properties */
  update_root_value("--audio-color", "rgb(56, 54, 54)");
  /* Audio Properties ends*/

  /* calendar Properties */
  update_root_value("--calendar-selected-box", "#8b8b8b");
  update_root_value("--datepicker-selected-color", "#fff");
  /* calendar Properties ends*/
} else if (project_name == "danielle-kelly") {
  material_theme_palate.main = "#ED5D25;";
  /* Main theme color */
  update_root_value("--portal-theme-primary", "rgba(237,93, 37, 1)");
  update_root_value("--portal-theme-secondary", "#ED5D2550");
  update_root_value("--text-primary-color", "black");
  update_root_value("--text-secondary-color", "rgb(150, 150, 150)");
  /* Main theme color end */

  /* Main background color*/
  update_root_value("--background-primary-color", "#fff");
  update_root_value("--background-secondary-color", "#fff");

  /* Main background color end*/

  /* Sidebar Properties */
  update_root_value("--sidebars-text-color", "black");
  update_root_value("--sidebars-active-color", "#C19D60");
  update_root_value("--sidebars-hover-color:", "#C19D60");
  update_root_value("--sidebars-background-color", "#ffffff");
  /* Sidebar Properties */

  /* Popup / Popover properties  */
  update_root_value("--popup-background-color", "#1d1c1d");
  update_root_value("--popup-text-color", "white");
  /* Popup / Popover properties end  */

  /* Element properties */
  update_root_value("--input-text-color", "black");
  update_root_value("--input-placeholder-color", "#fff");
  update_root_value("--input-background-primary", "#fff");
  update_root_value("--input-background-secondary", "#1d1c1d");
  update_root_value(" --icons-color-allover", "#C19D60");
  /* Element properties ends*/

  /* Button Properties */

  update_root_value("--button-background-color", "#ED5D25");
  update_root_value("--button-text-color", "#fff");
  update_root_value("--button-outlined-color", "#ED5D25");
  update_root_value("--button-hover-color", "#ED5D25");

  /* Button Properties ends*/

  /* Audio Properties */
  update_root_value("--audio-color", "rgb(56, 54, 54)");
  /* Audio Properties ends*/

  /* calendar Properties */
  update_root_value("--calendar-selected-box", "#8b8b8b");
  /* calendar Properties ends*/
} else if (project_name == "skin-sister") {
  material_theme_palate.main = main_color_skin_sister;
  /* Main theme color */
  update_root_value("--portal-theme-primary", main_color_skin_sister);
  update_root_value("--portal-theme-secondary", main_color_skin_sister);
  update_root_value("--text-primary-color", "black");
  update_root_value("--text-secondary-color", "rgb(150, 150, 150)");
  /* Main theme color end */

  /* Main background color*/
  update_root_value("--background-primary-color", "#fff");
  update_root_value("--background-secondary-color", "#fff");

  /* Main background color end*/

  /* Sidebar Properties */
  update_root_value("--sidebars-text-color", "black");
  update_root_value("--sidebars-active-color", "#C19D60");
  update_root_value("--sidebars-hover-color:", "#C19D60");
  update_root_value("--sidebars-background-color", "#ffffff");
  /* Sidebar Properties */

  /* Popup / Popover properties  */
  update_root_value("--popup-background-color", "#1d1c1d");
  update_root_value("--popup-text-color", "white");
  /* Popup / Popover properties end  */

  /* Element properties */
  update_root_value("--input-text-color", "black");
  update_root_value("--input-placeholder-color", "#fff");
  update_root_value("--input-background-primary", "#fff");
  update_root_value("--input-background-secondary", "#1d1c1d");
  update_root_value(" --icons-color-allover", "#C19D60");
  /* Element properties ends*/

  /* Button Properties */

  update_root_value("--button-background-color", main_color_skin_sister);
  update_root_value("--button-text-color", "#fff");
  update_root_value("--button-outlined-color", main_color_skin_sister);
  update_root_value("--button-hover-color", main_color_skin_sister);

  /* Button Properties ends*/

  /* Audio Properties */
  update_root_value("--audio-color", "rgb(56, 54, 54)");
  /* Audio Properties ends*/

  /* calendar Properties */
  update_root_value("--calendar-selected-box", "#8b8b8b");
  /* calendar Properties ends*/
} else if (project_name == "spirited") {
  material_theme_palate.main = "#cea254";
  /* Main theme color */
  update_root_value("--portal-theme-primary", "#cea254");
  update_root_value("--portal-theme-secondary", "#cea25450");
  update_root_value("--text-primary-color", "black");
  update_root_value("--text-secondary-color", "rgb(150, 150, 150)");
  update_root_value("--text-color", "black");

  /* Main theme color end */

  /* Main background color*/
  update_root_value("--background-primary-color", "#fff");
  update_root_value("--background-secondary-color", "#fff");

  /* Main background color end*/

  /* Sidebar Properties */
  update_root_value("--sidebars-text-color", "black");
  update_root_value("--sidebars-active-color", "#C19D60");
  update_root_value("--sidebars-hover-color:", "#C19D60");
  update_root_value("--sidebars-background-color", "#ffffff");
  /* Sidebar Properties */

  /* Popup / Popover properties  */
  update_root_value("--popup-background-color", "white");
  update_root_value("--popup-text-color", "#cea254");
  /* Popup / Popover properties end  */

  /* Element properties */
  update_root_value("--input-text-color", "black");
  update_root_value("--input-placeholder-color", "#fff");
  update_root_value("--input-background-primary", "#fff");
  update_root_value("--input-background-secondary", "#1d1c1d");
  update_root_value(" --icons-color-allover", "#C19D60");
  /* Element properties ends*/

  /* Button Properties */

  update_root_value("--button-background-color", "#000");
  update_root_value("--button-text-color", "#fff");
  update_root_value("--button-outlined-color", "#000");
  update_root_value("--button-hover-color", "#000");

  /* Button Properties ends*/

  /* Audio Properties */
  update_root_value("--audio-color", "rgb(56, 54, 54)");
  /* Audio Properties ends*/

  /* calendar Properties */
  update_root_value("--calendar-selected-box", "#8b8b8b");
  /* calendar Properties ends*/
} else if (project_name == "dd-default") {
  material_theme_palate.main = "rgba(55, 141, 212, 1)";
  /* Main theme color */
  update_root_value("--portal-theme-primary", "rgba(72, 121, 207, 1)"); //c
  update_root_value(
    "--portal-theme-secondary",
    "linear-gradient(90.79deg, rgba(55, 141, 212, 0.19) 6.86%, rgba(55, 141, 212, 0.19) 6.87%, rgba(55, 141, 212, 0.307187) 97.82%, rgba(56, 48, 35, 0.31) 100%)"
  ); //c
  update_root_value("--text-primary-color", "white"); //c
  update_root_value("--text-secondary-color", "white");
  update_root_value("--text-color", "white");
  /* Main theme color end */

  /* Main background color*/
  update_root_value("--background-primary-color", "rgba(18, 24, 38, 1)");
  update_root_value("--background-secondary-color", "#132144");

  /* Main background color end*/

  /* Sidebar Properties */
  update_root_value("--sidebars-text-color", "white");
  update_root_value("--sidebars-active-color", "white");
  update_root_value("--sidebars-hover-color", "white");
  update_root_value("--sidebars-background-color", "#132144");
  /* Sidebar Properties */

  /* Popup / Popover properties  */
  update_root_value("--popup-background-color", "#132144");
  update_root_value("--popup-text-color", "black");
  /* Popup / Popover properties end  */

  /* Element properties */
  update_root_value("--input-text-color", "white");
  update_root_value("--input-placeholder-color", "black"); //not yet
  update_root_value("--input-background-primary", "#fff"); //not yet
  update_root_value("--input-background-secondary", "#EAEAEA");
  update_root_value("--icons-color-allover", "rgba(72, 121, 207, 1)");
  /* Element properties ends*/

  /* Button Properties */
  update_root_value("--button-background-color", "rgba(18, 24, 38, 1)");
  update_root_value("--button-text-color", "#fff");
  update_root_value("--button-outlined-color", "#1a93a9");
  update_root_value("--button-hover-color", "#1a93a9");
  /* Button Properties ends*/

  /* Audio Properties */
  update_root_value("--audio-color", "#F1F3F4");
  /* Audio Properties ends*/

  /* calendar Properties */
  update_root_value("--calendar-selected-box", "#8b8b8b");
  /* calendar Properties ends*/
} else if (project_name == "danielle-dev") {
  material_theme_palate.main = "#C19D60";
  /* Main theme color */
  update_root_value("--portal-theme-primary", "#C19D60");
  update_root_value("--portal-theme-secondary", "rgb(255 218 0 / 8%)");
  update_root_value("--text-primary-color", "white");
  update_root_value("--text-secondary-color", "rgb(150, 150, 150)");
  /* Main theme color end */

  /* Main background color*/
  update_root_value("--background-primary-color", "black");
  update_root_value("--background-secondary-color", "#1d1c1d");

  /* Main background color end*/

  /* Sidebar Properties */
  update_root_value("--sidebars-text-color", "white");
  update_root_value("--sidebars-active-color", "#C19D60");
  update_root_value("--sidebars-hover-color:", "#C19D60");
  update_root_value("--sidebars-background-color", "#1d1c1d");
  /* Sidebar Properties */

  /* Popup / Popover properties  */
  update_root_value("--popup-background-color", "#1d1c1d");
  update_root_value("--popup-text-color", "white");
  /* Popup / Popover properties end  */

  /* Element properties */
  update_root_value("--input-text-color", "#fff");
  update_root_value("--input-placeholder-color", "#fff");
  update_root_value("--input-background-primary", "#1d1c1d");
  update_root_value("--input-background-secondary", "#1d1c1d");
  update_root_value(" --icons-color-allover", "#C19D60");
  /* Element properties ends*/

  /* Button Properties */

  update_root_value("--button-background-color", "#1c1c1c");
  update_root_value("--button-text-color", "#C19D60");
  update_root_value("--button-outlined-color", "#C19D60");
  update_root_value("--button-hover-color", "#C19D60");

  /* Button Properties ends*/

  /* Audio Properties */
  update_root_value("--audio-color", "rgb(56, 54, 54)");
  /* Audio Properties ends*/

  /* calendar Properties */
  update_root_value("--calendar-selected-box", "#8b8b8b");
  /* calendar Properties ends*/
} else {
  material_theme_palate.main = "#1a93a9";
  /* Main theme color */
  update_root_value("--portal-theme-primary", "#1a93a9");
  update_root_value(
    "--portal-theme-primary-filter-color",
    "invert(42%) sepia(98%) saturate(369%) hue-rotate(141deg) brightness(94%) contrast(94%)"
  );
  update_root_value("--portal-theme-secondary", "rgb(0 171 85 / 8%)");
  update_root_value("--text-primary-color", "black");
  update_root_value("--text-secondary-color", "rgb(150, 150, 150)");
  /* Main theme color end */

  /* Main background color*/
  update_root_value("--background-primary-color", "white");
  update_root_value("--background-secondary-color", "white");

  /* Main background color end*/

  /* Sidebar Properties */
  update_root_value("--sidebars-text-color", "black");
  update_root_value("--sidebars-active-color", "#1a93a9");
  update_root_value("--sidebars-hover-color", "#1a93a9");
  update_root_value("--sidebars-background-color", "white");
  /* Sidebar Properties */

  /* Popup / Popover properties  */
  update_root_value("--popup-background-color", "#fff");
  update_root_value("--popup-text-color", "black");
  /* Popup / Popover properties end  */

  /* Element properties */
  update_root_value("--input-text-color", "black");
  update_root_value("--input-placeholder-color", "black"); //not yet
  update_root_value("--input-background-primary", "#fff"); //not yet
  update_root_value("--input-background-secondary", "#EAEAEA");
  update_root_value(" --icons-color-allover", "#f6bd4b");
  /* Element properties ends*/

  /* Button Properties */
  update_root_value("--button-background-color", "#1a93a9");
  update_root_value("--button-text-color", "#fff");
  update_root_value("--button-outlined-color", "#1a93a9");
  update_root_value("--button-hover-color", "#1a93a9");
  /* Button Properties ends*/

  /* Audio Properties */
  update_root_value("--audio-color", "#F1F3F4");
  /* Audio Properties ends*/

  /* calendar Properties */
  update_root_value("--calendar-selected-box", "#8b8b8b");
  update_root_value("--datepicker-selected-color", "#fff");
  /* calendar Properties ends*/
}
